import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import API from "../../api";
import dummyJson from "./dummyJson.json";

import { store } from "../../store";
import {
  updateLastEarnings,
  addLastEarnings,
} from "../../store/actions/lastEarnings";

import UserCard from "./UserCard";

import style from "./style.module.scss";

export default function LastEarnings() {
  const Client = API.getInstance();
  const users = useSelector((state) => state.lastEarningsReducer.users);
  const container = useRef();
  const router = useLocation();
  const routerHistory = useHistory();
  const [popup, setPopup] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [scrollX, setScrollX] = useState(0);

  const fake = dummyJson;

  function generateCoins() {
    let randomNumber = Math.random();
    if (randomNumber < 0.75) {
      let number = Math.floor(Math.floor(Math.random() * 370) / 10) * 10 + 10; // generate a random number between 10 and 3000 rounded down to the nearest ten for 80% of users
      let lastDigit = number % 10;
      if (lastDigit >= 5) {
        number = number - lastDigit + 10; // round up to the nearest ten if the last digit is 5 or greater
      } else {
        number = number - lastDigit; // round down to the nearest ten if the last digit is less than 5
      }
      return number;
    }
    if (randomNumber < 0.8) {
      let number = Math.floor(Math.floor(Math.random() * 2991) / 10) * 10 + 10; // generate a random number between 10 and 3000 rounded down to the nearest ten for 80% of users
      let lastDigit = number % 10;
      if (lastDigit >= 5) {
        number = number - lastDigit + 10; // round up to the nearest ten if the last digit is 5 or greater
      } else {
        number = number - lastDigit; // round down to the nearest ten if the last digit is less than 5
      }
      return number;
    } else if (randomNumber < 0.85) {
      let number = Math.floor(Math.random() * 11) * 10 + 3100; // generate a random number between 3100 and 3200 rounded down to the nearest ten for 5% of users
      let lastDigit = number % 10;
      if (lastDigit >= 5) {
        number = number - lastDigit + 10; // round up to the nearest ten if the last digit is 5 or greater
      } else {
        number = number - lastDigit; // round down to the nearest ten if the last digit is less than 5
      }
      return number;
    } else if (randomNumber < 0.99) {
      let number =
        Math.floor(Math.floor(Math.random() * 13701) / 10) * 10 + 3300; // generate a random number between 3300 and 17000 rounded down to the nearest ten for 14% of users
      let lastDigit = number % 10;
      if (lastDigit >= 5) {
        number = number - lastDigit + 10; // round up to the nearest ten if the last digit is 5 or greater
      } else {
        number = number - lastDigit; // round down to the nearest ten if the last digit is less than 5
      }
      return number;
    } else {
      let number =
        Math.floor(Math.floor(Math.random() * 4501) / 10) * 10 + 18500; // generate a random number between 18500 and 23000 rounded down to the nearest ten for 0.5% of users
      let lastDigit = number % 10;
      if (lastDigit >= 5) {
        number = number - lastDigit + 10; // round up to the nearest ten if the last digit is 5 or greater
      } else {
        number = number - lastDigit; // round down to the nearest ten if the last digit is less than 5
      }
      return number;
    }
  }
  /* useEffect(async () => {
    const response = await Client.getLastEarnings();
    store.dispatch(updateLastEarnings(response.data));
    let timeout = setInterval(async () => {
      let randomid = Math.floor(Math.random() * 290);

      const regularUser = await Client.getRegularUser(
        randomid,
        generateCoins()
      );
      const response = await Client.getLastEarnings();
      store.dispatch(updateLastEarnings(response.data));
      setAnimate(true);
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 2000);
       var random = fake[Math.floor(Math.random() * fake.length)];
       store.dispatch(addLastEarnings(random));
    }, [450000]);
  }, []);*/

  useEffect(async () => {
    const response = await Client.getLastEarnings();
    store.dispatch(updateLastEarnings(response.data));
  }, []);

  return (
    <div className={style.wrapper}>
      <div
        className={style.container}
        onWheelCapture={(event) => {
          container.current.scrollLeft += event.deltaY * 6;
          setScrollX(container.current.scrollLeft);
        }}
        ref={container}
      >
        {users.map((user, index) => (
          <div
            onClick={() => {
              /*if (popup !== null) {
                routerHistory.push(`${router.pathname}?popup=user-details`, {
                  userData: popup.data,
                });
              }*/
            }}
            className={`${index != 0 && style.userWrapper} ${
              index == 0 && animate && style.activeUserWrapper
            }`}
            key={Math.random()}
          >
            <UserCard
              user={user}
              key={index}
              scrollX={scrollX}
              setPopup={setPopup}
            />
          </div>
        ))}
      </div>
      {/* <div className={style.betaRelease}>
        <p
          className={style.text}
          onClick={() => {
            routerHistory.push(`${router.pathname}?popup=survey`);
          }}
        >
          Tell us how to get better, fill out our survey.
        </p>
      </div> */}
      {popup && (
        <div className={style.popup} style={{ left: popup.x }}>
          <div className={style.popupContainer}>
            <div className={style.titlesContainer}>
              {/* <p className={style.item}>Offer Name:</p> */}
              <p className={style.item}>Offerwall:</p>
              <p className={style.item}>Reward:</p>
            </div>
            <div className={style.titlesData}>
              {/* <p className={style.item}>{popup.data.offer_name}</p> */}
              <p className={style.item}>{popup.data.offerwall}</p>
              <p className={style.item}>{popup.data.amount} Coins</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
